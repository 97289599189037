body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.skiptranslate.goog-te-gadget span,.goog-te-banner-frame{
  display: none !important;
}
.goog-te-gadget{
  color:white !important;
}
.d_flex_center{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.d_flex_start{
  display: flex;
  align-items: center;
  justify-content: center;
}  
.d_flex_left{
  display: flex;
  align-items: center;
  justify-content: start;
}  
.inactive_btn{
position: absolute;
right:0;
bottom: 0;
border-radius: 5px 0px 5px 0px;
border: none;
color:#E8221D;
background-color: #F8C0BE;
padding: 6px 26px;
font-size: 12px;
font-weight: 400;
}
.active_btn{
  position: absolute;
  right:0;
  bottom: 0;
  border-radius: 5px 0px 5px 0px;
  border: none;
  background-color: #32B400;
  color:white;
  padding: 6px 31px;
  font-size: 12px;
  font-weight: 400;
}
.p_inprogress{
color:yellow;
font-weight: bold !important;
}
.p_delivered{
  color:#26C152;
  font-weight: bold !important;
}
.p_cancelled{
color:#E00000;
font-weight: bold !important;
position: relative;
}
.p_colorred{
  color:#ffffff;
  font-weight: bold !important;
  position: relative;
  border-bottom: 1px solid white;
  }

.p_new{
color:#00B9CF;
font-weight: bold !important;
}